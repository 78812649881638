<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id + '/exhibitionhall'}">展馆管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑展馆</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="pageRef" :model="pageForm" label-width="150px">
        <el-form-item label="排序">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="展馆标题">
          <el-input v-model="pageForm.title" placeholder="请输入展馆标题"></el-input>
        </el-form-item>
        <el-form-item label="展馆标题英文">
          <el-input v-model="pageForm.entitle" placeholder="请输入展馆标题(英文)"></el-input>
        </el-form-item>
        <el-form-item label="缩略图">
          <upload :url="pageForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="内页滚动图">
          <upload :urlArr="pageForm.banner" :filed="'banner'" :max-num="5" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="是否显示申请参展">
          <el-radio-group v-model="pageForm.is_apply">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否显示合作洽谈">
          <el-radio-group v-model="pageForm.is_chat">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合作洽谈链接">
          <el-input v-model="pageForm.chat_link" placeholder="请输入合作洽谈链接"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">更 新</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      exhibitionTitle: '',
      pageForm: {
        m_id: '',
        sort: 0,
        title: '',
        entitle: '',
        thumbnail: '',
        banner: [],
        is_apply: 1,
        is_chat: 1,
        chat_link: ''
      },
      pageRules: {
        title: [
          { required: true, message: '请填写展馆标题', trigger: 'blur' },
          { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
        ],
        entitle: [
          { required: true, message: '请填写展馆标题(英文)', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传展馆缩略图', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getPageData()
    this.getExhibitionTitle()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/exhibition-exhibitionhall', { params: { m_id: this.$route.params.item_mid } })
      if (res.status === 200) {
        this.pageForm.m_id = res.data.m_id
        this.pageForm.sort = res.data.sort
        this.pageForm.title = res.data.title
        this.pageForm.entitle = res.data.entitle
        this.pageForm.thumbnail = res.data.thumbnail
        this.pageForm.banner = res.data.banner
        this.pageForm.is_apply = res.data.is_apply
        this.pageForm.is_chat = res.data.is_chat
        this.pageForm.chat_link = res.data.chat_link
      } else {
        this.$message.error(res.msg)
      }
    },
    onSubmit () {
      this.$refs.pageRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/exhibition-exhibitionhall', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.pageRef.resetFields()
            await this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/exhibitionhall')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    cancel () {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/exhibitionhall')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    getExhibitionTitle () {
      this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
    },
    itemChange (val) {
      this.pageForm.content = val
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
</style>
